.service {
    font-size: 16;
    &--sectionLeft {
        h2 {
            text-align: center;
        }
    }
    &--sectionCenter {
        h2,
        p {
            text-align: center;
        }
    }
    &--sectionRight {
        h2 {
            text-align: right;
        }
    }

    &__section {
        padding: 3em 0;
    }

    &__content {
        text-align: left;
        margin: 0 auto;

        @media screen and (max-width: 425px) {
            font-size: 20px;
            padding: 0 2em !important;
        }
        h2 {
            margin-bottom: 2em;
            font-size: 2rem;
            font-weight: 600;
        }
        ul {
            list-style-type: none;
        }
    }
}

.consultation,
.backEnd {
    background: linear-gradient(#002b70, #333399);
    color: white;
    h2,
    h3,
    h4 {
        color: white;
        font-weight: 600;
    }
}

.audit {
    h3 {
        font-weight: 600;
        text-align: center;
    }

    ul {
        display: flex;
        flex-flow: wrap;
        justify-content: space-evenly;
        text-align: center;
        margin: 0;
        padding: 0;
    }
    .leftList,
    .rightList {
        flex-basis: 50%;

        li {
            margin: 0 auto;
            width: 70%;
        }
    }
}

.design {
    background: linear-gradient(#333399, #002b70);
    color: white;
    h2,
    h3,
    h4 {
        color: white;
        font-weight: 600;
    }
}

.frontEnd {
    h3 {
        font-weight: 600;
    }
    &__lists {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
    }

    &__listSection {
        text-align: center;
        flex-basis: 30%;
        flex: 1;
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }
    }
}
