.hero {
    margin: 2em 0;
    color: black;
    &__container {
        position: relative;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-items: center;
        @media screen and (max-width: 768px) {
            max-width: 85vw;
            display: block;
        }
    }

    &_left,
    &_right {
        text-align: left;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
        min-height: 500px;
        height: 100%;

        @media screen and (max-width: 768px) {
            text-align: center;
            margin: 0 auto;
        }
        h2 {
            font-size: 3em;
        }
    }

    &_left {
        z-index: 2;
        &_container {
            // background-color: #fffffff1;
            padding: 2em;
            border-radius: 10px;
            // box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
            width: fit-content;

            &_bg {
                position: relative;

                img {
                    max-width: 100%;
                }
            }
        }
    }

    &_right {
        @media screen and (max-width: 768px) {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }

        svg {
            position: absolute;
            left: 0;
            height: 125%;
            max-height: 550px;
            transition: all 0.5s ease-in-out;

            @media screen and (max-width: 768px) {
                left: 0%;
                width: 100%;
            }
        }
    }
}

.cta {
    background: linear-gradient(#1a4790, #002b70);
    color: #efefef;
    padding: 2em 1em;
    margin-top: 5em;

    @media screen and (max-width: 768px) {
        margin-top: 0;
    }

    &__container {
        margin: 0 auto;
        padding: 2em;

        h3 {
            color: white;
            font-size: 2.25em;
        }

        p {
            font-size: 1.5em;
            max-width: 100%;
        }
    }
}

.window {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em;
    font-size: 1.5em;

    p {
        margin: 0;
    }

    a {
        color: #002b70;
    }
}

.section-2 {
    padding: 2em;
    background: linear-gradient(#002b70, #333399);

    .slick-dots {
        position: relative;
    }

    .slick-dots-bottom {
        bottom: 0 !important;
    }
}

.review {
    margin: 0 auto;
    min-height: 204.5px;
    h2,
    p {
        color: white;
    }

    &__loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-height: 204.5px;
        margin: 0;
        padding: 0;
    }

    &__item {
        border-radius: 5px;
        margin: 1em 0;
        padding: 1em;
        line-height: 2;

        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-items: center;
    }

    &__statement,
    &__author {
        margin: 0;
        padding: 0 1em;
    }

    &__statement {
        font-size: 1.25rem;
    }
    &__author {
        font-size: 1rem;
    }

    &__stars {
        --star-size: 30px;
        --star-color: #fff;
        --star-background: #fc0;
        --percent: calc(var(--rating) / 5 * 100%);

        display: inline-block;
        font-size: var(--star-size);
        font-family: Times;
        line-height: 1;

        &::before {
            content: '★★★★★';
            letter-spacing: 3px;
            background: linear-gradient(
                90deg,
                var(--star-background) var(--percent),
                var(--star-color) var(--percent)
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

.services {
    height: 100%;
    margin: 1em 0;

    &__container {
        margin: 2em auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            font-size: 3em;
        }
    }

    &__boxContainer {
        width: 100%;
        flex: 1 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
    }

    &__card {
        width: 100%;
        flex: 1;
        @media screen and (max-width: 1023px) {
            flex-basis: 80%;
            margin: 1em;
        }
        @media screen and (max-width: 768px) {
            flex-basis: 100%;
        }

        border-radius: 25px !important;
        flex-basis: 30%;

        display: flex;
        flex-flow: column;
    }

    &__cardHeader {
        @media screen and (max-width: 1023px) {
            font-size: 24px;
        }

        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px 25px 0 0;
        background-color: #5e6163;
        h3 {
            @media screen and (max-width: 768px) {
                font-size: 24px;
                padding: 0.5em;
            }
            color: white;
            padding: 0.5em 1em;
            margin: 0;
            font-size: 1.5em;
        }
    }

    &__cardBody {
        background: white;
        color: black;
        padding: 1em;
        border-radius: 0 0 25px 25px;
        flex: 1 0;

        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
        height: 100%;

        &__item {
            @media screen and (max-width: 425px) {
                font-size: 16px;
            }

            font-size: 20px;
            display: grid;
            grid-template-columns: 20% 80%;
            align-items: center;

            .icon {
                margin: 0 0.5em;
            }

            p {
                text-align: left;
                margin: 0;
            }
        }
    }

    &__sideBox {
        min-height: 250px;
        height: 95%;
        border-radius: 25px;
        @media screen and (max-width: 768px) {
            width: 90vw;
        }
    }

    &__middleBox {
        min-height: 300px;
        z-index: 2;

        .services__cardHeader {
            @media screen and (max-width: 1023px) {
                font-size: 24px;
            }
            background-color: #33996f;
            font-size: 0.9rem;
        }
    }
}
