@import './About';
@import './Contact';
@import './Header';
@import './Home';
@import './servicePage';
@import 'antd/dist/antd.css';

html,
body,
#__next,
.App {
    height: 100%;
    overflow-x: hidden;
}

#__next {
    display: flex;
    flex-direction: column;
    text-align: center;
    .content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
    }

    footer {
        flex-shrink: 0;
    }
}

.contentContainer {
    max-width: 1080px;
    padding: 0 1em;

    @media only screen and (max-device-width: 768px) {
        padding: 0 1em;
    }
}

.calendly-badge-widget {
    bottom: 2em !important;
}

.site-back-top-basic {
    color: rgba(64, 64, 64, 0.6);
}
