.kyle,
.jesse {
    position: relative;

    &__content {
        max-height: 1080px;
        position: relative;
        display: flex;
        margin: 0 auto;
        overflow: hidden;
    }

    .jesse__content {
        flex-direction: row-reverse;
    }

    &__imageContainer {
        width: 80%;
        overflow: hidden;

        img {
            object-position: left;
            object-fit: cover;
            height: 100%;
            @media screen and (max-width: 768px) {
			object-position: center;
                width: 75%;
                min-height: 500px;
            }
        }
    }

    &__textContainer {
        width: 40%;
        height: 100%;
        right: 0;
        position: absolute;
        background-color: #ffffffe0;
        color: black;
        padding: 3em 2em;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        font-size: 18px;

        h3 {
            font-size: 2rem;
        }

        p {
            text-align: left;
            text-indent: 2em;
        }
    }

    .jesse__textContainer {
        right: unset;
        left: 0;
    }

    &__paragraphs {
        box-sizing: border-box;
        padding: 1em 2em;
        height: 60%;
        overflow-y: scroll;
    }

    .custom-shape-divider-bottom-1629763950 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .custom-shape-divider-bottom-1629763950 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 100px;
    }

    .custom-shape-divider-bottom-1629763950 .shape-fill {
        fill: #ffffff;
    }

    .custom-shape-divider-bottom-1629770768 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .custom-shape-divider-bottom-1629770768 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 141px;
        transform: rotateY(180deg);
    }

    .custom-shape-divider-bottom-1629770768 .shape-fill {
        fill: #ffffff;
    }

    .custom-shape-divider-top-1629824936 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
    }

    .custom-shape-divider-top-1629824936 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 100px;
    }

    .custom-shape-divider-top-1629824936 .shape-fill {
        fill: #ffffff;
    }
}
