.NavHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    padding: 1em;
}

.nav {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    &__logo {
        max-width: 150px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: auto;
            height: 100%;
        }

        @media screen and (max-width: 768px) {
            display: none;
        }
    }
}

.navLink {
    position: relative;
    font-weight: 400;
    width: 150px;
    height: 100%;
    text-decoration: none;
    text-transform: uppercase;
    transition: background 0.3s ease-in-out;

    display: flex;
    align-items: center;
    justify-content: center;

    a {
        font-size: 3vmin;
        color: black;
    }

    &__bottomBar {
        position: absolute;
        bottom: 0px;
        left: 50%;
        right: 50%;
        border-bottom: 3px solid #1a4790;
        transition: all 0.2s ease-in-out;
    }

    &:hover,
    &:active,
    &.active {
        color: #1a4790;
        background-color: #fafafa;

        &__bottomBar {
            left: 0;
            right: 0;
        }
    }

    &.active {
        background-color: #fff;
    }

    @media screen and (max-width: 768px) {
        a {
            font-size: 3vmax;
        }
    }
}
