.contentContainer.contactContainer {
    margin: 0 auto;
    align-self: flex-start;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}

.contact {
    align-self: center;
    justify-self: center;
    flex: 1 0;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;

    width: 100%;
    margin: 2em 0;
    // background-color: #01a2ffb0;

    font-size: 16px;

    a {
        // color: white;
        :hover {
            text-decoration: underline;
        }
    }

    h1 {
        font-size: 3rem;
        margin: 0;
    }

    h2 {
        font-size: 2rem;
    }
    h3 {
        font-size: 1.5rem;
    }

    li {
        font-size: 1.25em;
        font-weight: 600;
    }

    &__wrapper {
        display: grid;
        grid-gap: 2em;
        align-items: center;
        justify-content: center;
        margin: 1em auto;
        padding: 1em;
    }

    &__info {
        padding: 0 1em 1em;
        border-bottom: 1px solid black;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        div {
            padding: 1em;
            flex: 1;
            flex-basis: 33%;
        }
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        @media screen and (max-width: 765px) {
            flex-flow: column nowrap;
        }
    }

    &__social ul {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            margin-right: 1em;
        }
    }
}
